import utils from "../../internals/utils.js";
import { Buffer } from "buffer";

/**
 * @param {string} defaultMessage
 * @param {{ statusCode?: number, body?: {message?: string, stack?: string}, response?: Response } | never} result
 * @param {boolean} [doNotReload=false]
 * @returns {Error} error with server message and stack-trace (if debug mode is enabled), or current message and stack-trace
 */
function getError(defaultMessage, result, doNotReload = false) {
  const error = new Error(defaultMessage);
  if (result?.body?.message) error.message = result.body.message;
  if (result?.body?.stack) error.stack = result.body.stack;

  if (result.statusCode === 401) {
    api.clearSessionToken();
    if (!doNotReload) location.reload();
  }

  return error;
}

class API {
  static _URI = "/api/v1";
  // static _URI = "http://192.168.40.2:5000/api/v1";
  static _lsKey = "__SESSION";
  constructor() {}

  _version = 1;
  _token = "";
  _timestamp = -1;
  _maxts = -1;
  /**
   * @type {{
   *  aud: string,
   *  exp: number,
   *  iat: number,
   *  iss: string,
   *  sub: string,
   *  token: string,
   *  type: string,
   *  ua: number,
   *  uah: string,
   *  jti: string,
   *  id: string,
   * }}
   */
  _info = null;

  isTokenAlive() {
    return this.getExpireTime() > Date.now();
  }
  getExpireIn() {
    return Math.max(this.getExpireTime() - Date.now(), 0);
  }
  getExpireTime() {
    if (!this._info) this.getTokenInfo();
    return (this._info?.exp || 0) * 1e3;
  }
  getSessionType() {
    if (!this._info) this.getTokenInfo();
    return this._info?.type || "";
  }
  getUserLogin() {
    if (!this._info) this.getTokenInfo();
    return this._info?.sub || "";
  }

  getFrontendRootPath() {
    return "/";
  }

  /**
   * @param {String} [token=this._token]
   * @return {{
   *  aud: String,
   *  exp: Number,
   *  iat: Number,
   *  iss: String,
   *  sub: String,
   *  token: String,
   *  type: String,
   *  ua: Number,
   *  uah: String,
   * }}
   */
  getTokenInfo(token) {
    if (!token) {
      if (this.isTokenPresent()) {
        token = this._token;
      } else {
        return {};
      }
    }
    if (this._info && this._token === token) {
      return this._info;
    }
    const info = utils.jsonParseSafe(
      Buffer.from(token.split(".")[1], "base64").toString()
    );
    if (this._token === token) {
      this._info = info;
    }
    return info;
  }

  registerSessionToken(token) {
    this._timestamp = Date.now();
    this._info = this.getTokenInfo(token);
    this._token = token;
    this._maxts = this._info.exp * 1e3;
    window.localStorage.setItem(
      API._lsKey,
      JSON.stringify({
        token: this._token,
        timestamp: this._timestamp,
        maxts: this._maxts,
        version: this._version,
      })
    );
  }

  clearSessionToken() {
    this._token = "";
    this._timestamp = -1;
    this._info = {};
    this._maxts = -1;
    window.localStorage.removeItem(API._lsKey);
  }

  /**
   * Check, is token present or not
   * @return {Boolean}
   */
  isTokenPresent() {
    if (!this._token) {
      const lsToken = window.localStorage.getItem(API._lsKey);
      if (lsToken) {
        const res = utils.jsonParseSafe(lsToken);
        if (res && res.token && res.timestamp > 0) {
          this._timestamp = res.timestamp;
          this._token = res.token;
          this._maxts = res.maxts;
          this._version = res.version;

          return true;
        }
      }
      return false;
    }
    return true;
  }

  /**
   * Check, is token must be re-validated
   * @return {Boolean}
   */
  mustRevalidateToken() {
    this.isTokenPresent();
    this._info = this._info || this.getTokenInfo();
    if (
      Date.now() >
      this._info.exp * 1e3 - 60e3 // expire at - 60s
    ) {
      return true;
    }
    return false;
  }

  send_validateOptions(options) {
    options = options || {};
    options.url = API._URI + (options.url || "/");
    options.method = options.method || "GET";
    options.headers = options.headers || {};
    if (
      options.body instanceof Object &&
      !("Content-Type" in options.headers)
    ) {
      options.headers["Content-Type"] = "application/json;charset=utf-8";
      options.body = JSON.stringify(options.body);
    }
    if (this._token && !("Authorization" in options.headers)) {
      options.headers["Authorization"] = "Bearer " + this._token;
    }
    return options;
  }

  /**
   * Send request to server and receive response from it
   * @param {Object} [options]
   * @param {String} [options.url='/']
   * @param {'GET'|'POST'|'PUT'|'DELETE'} [options.method='GET']
   * @param {Object} [options.headers]
   * @param {Object} [options.body]
   * @param {Object} [options.token]
   * @return {{statusCode: Number, body: any, response: Response}}
   */
  async send(options) {
    options = this.send_validateOptions(options);
    const response = await fetch(options.url, options);
    const output = {
      statusCode: response.status,
      body: null,
      response,
    };
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      // can read json data
      output.body = await response.json();
    }
    return output;
  }

  /**
   * Request from backend to send SMS 2FA code to Client
   * @param {String} login
   * @returns {Boolean}
   */
  async auth_requestCodeSending(login) {
    const result = await this.send({
      url: "/auth/request2FACode",
      method: "POST",
      body: {
        login,
      },
    });

    if (result.response.ok) {
      return result.body?.status === "success" || false;
    } else {
      throw new Error("Server responded with incorrect status");
    }
  }

  /**
   * Sends 2FA code from user to backend
   * @param {String} login
   * @param {String} code
   * @returns {{status:'success'|'failure', token?: String, message?: String}}
   */
  async auth_sendCode(login, code) {
    const fp = await utils.Fingerprints();
    const incognito = await utils.IncognitoMode;

    const result = await this.send({
      url: "/auth/login",
      method: "POST",
      body: {
        login,
        code,
        uah: fp.sha256,
        incognito: incognito,
      },
    });

    if (!result.response.ok) {
      throw getError("something went wrong", result, true);
    }

    // automatically update token
    if (result.body.status === "success" && result.body.token) {
      this.registerSessionToken(result.body.token);
    }

    return result.body;
  }

  /**
   * @returns {Promise<{status:'success'|'failure', data: Array<{userID: string, userName: string, userType: string}>}>}
   */
  async auth_getSessionUsers() {
    const result = await this.send({
      url: "/auth/sessionUsers",
      method: "GET",
    });

    if (!result.response.ok) {
      throw getError("no users found for session", result);
    }

    return result.body;
  }

  /**
   * @param {Object} user
   * @param {string} user.userID
   * @param {string} [user.userName]
   * @param {string} user.userType
   * @returns {Promise<{status:'success'|'failure', token: string}>}
   */
  async auth_setSessionUser(user) {
    const result = await this.send({
      url: "/auth/setSessionUser",
      method: "POST",
      body: user,
    });

    if (!result.response.ok) {
      throw getError("can't set user for session", result);
    }

    // automatically update token
    if (result.body?.status === "success" && result.body?.token) {
      this.registerSessionToken(result.body.token);
    }

    return result.body;
  }

  /**
   * @returns {Promise<{status:'success'|'failure', token: string}>}
   */
  async auth_refreshToken() {
    const fp = await utils.Fingerprints();
    const incognito = await utils.IncognitoMode;

    const result = await this.send({
      url: "/auth/refreshToken",
      method: "POST",
      body: {
        login: this.getUserLogin(),
        uah: fp.sha256,
        incognito: incognito,
      },
    });

    if (!result.response.ok) {
      throw getError("can't refresh token", result);
    }

    // automatically update token
    if (result.body.status === "success" && result.body.token) {
      this.registerSessionToken(result.body.token);
    }

    return result.body;
  }

  async auth_revalidateToken() {}
}

const api = new API();

export default api;
