import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";
import primeVueCustomLocale from "./assets/primeVueLocale.js";

import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";

createApp(App)
  .use(router)
  .use(PrimeVue, {
    ripple: true,
    locale: primeVueCustomLocale,
  })
  .directive("tooltip", Tooltip)
  .use(ToastService)
  .mount("#app");
