<template>
  <Toast />
  <router-view />
</template>

<script>
import Toast from "primevue/toast";

export default {
  name: "App",
  components: {
    Toast,
  },
};
</script>

<style>
body {
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
#app {
  width: 100%;
  height: 100%;
}
</style>
