import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import API from "../API";

const routes = [
  {
    path: "/",
    name: "dashboard",
    meta: {
      allowedSTypes: ["person"],
      title: "Главная страница",
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      allowedSTypes: ["admin"],
    },
  },
  {
    path: "/login",
    name: "login",
    beforeEnter: (to, from, next) => {
      if (API.isTokenAlive()) {
        next({ name: "dashboard" });
        return;
      }
      next(true);
    },
    meta: {
      title: "Вход",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/login/chooseUser",
    name: "loginChooseUser",
    meta: {
      allowedSTypes: ["unknown"],
      title: "Вход - Выбор пользователя",
    },
    // beforeEnter: (to, from, next) => {
    //   if (API.isTokenAlive()) {
    //     if (API.getSessionType() === "unknown") {
    //       next(true);
    //     } else {
    //       next({ name: "dashboard" });
    //     }
    //   } else {
    //     next({ name: "login" });
    //   }
    // },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/LoginChooseUser.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    meta: {
      title: "Страница не найдена",
    },
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function updateTitle(page) {
  document.title = page.meta?.title || page.name;
}

router.beforeEach((to, from, next) => {
  console.log(to, from);

  if (!to.meta?.allowedSTypes) {
    updateTitle(to);
    next(true);
    return;
  }

  const isAlive = API.isTokenAlive();
  if (!isAlive) {
    API.clearSessionToken();
  }
  const sType = API.getSessionType();
  console.log(API);
  if (sType === "unknown" && to.name !== "loginChooseUser") {
    next({ name: "loginChooseUser" });
    console.log("moved to user choosing dialog");
    return;
  }
  if (
    to.meta?.allowedSTypes?.includes(sType) ||
    to.meta?.allowedSTypes?.includes("all")
  ) {
    updateTitle(to);
    next(true);
    console.log("moved to", to);
    return;
  }
  next({ path: "/login", replace: true, name: "login" });
});

export default router;
