import IncognitoMode from "./utils/IncognitoMode";
import Fingerprints from "./utils/UserAgentFingerprinting";

/**
 * @param {String} str
 * @return {any | null} JSON parse result or null
 */
function jsonParseSafe(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

export default {
  IncognitoMode,
  Fingerprints,
  jsonParseSafe,
};
