const resolvedValues = {
  b64: "",
  ts: 0,
  sha256: "",
  sha512: "",
  md5: "",
  crc: "",
};

// let sha256, sha512, md5, PngToy;

// import sha256 from "../sha256.min.js";
// import sha512 from "../sha512.min.js";
// import md5 from "../md5.js";
// import PngToy from "../pngtoy.js";

const allowFingerprints = ["sha256" /*, "sha512", "md5", "crc"*/];

/**
 * Throws an TypeError, if CanvasRenderingContext2D is not supported
 * @returns {String}
 */
function calculateCanvasData() {
  const cnv = document.createElement("canvas");
  if (!cnv.getContext || CanvasRenderingContext2D === undefined) {
    throw new TypeError("This Browser does not support Canvas HTML element.");
  }
  const ctx = cnv.getContext("2d");
  cnv.width = 64;
  cnv.height = 32;
  ctx.fillStyle = "#deadbeef";
  ctx.fillRect(0, 0, cnv.width, cnv.height);
  ctx.font = "0.15in monospace";
  ctx.fillStyle = "#beefface";
  ctx.textBaseline = "top";
  ctx.fillText("fingerprint", 0, 0);
  let d = cnv.toDataURL();
  d = atob(d.slice(d.indexOf("base64,") + 7));

  return d;
}

function calculatePngToyCRC() {
  return new Promise((resolve, reject) => {
    const buf = Uint8Array.from(resolvedValues.b64, (c) => c.charCodeAt(0));
    const t = new window.PngToy.PngToy({ doCRC: true });
    t.fetch(buf)
      .then(() => {
        for (let i = 0; i < t.chunks.length; i++) {
          if (t.chunks[i].name === "IDAT") {
            resolve(
              ("00000000" + t.chunks[1].crc.toString(16).toUpperCase()).slice(
                -8
              )
            );
          }
        }
      })
      .catch(reject);
  });
}

async function calculateFingerprints() {
  let recalculateAll = false;
  if (!resolvedValues.b64 || resolvedValues.ts < Date.now() - 36e6) {
    recalculateAll = true;
    resolvedValues.b64 = calculateCanvasData();
    resolvedValues.ts = Date.now();
  }
  if (recalculateAll) {
    for (let i = 0; i < allowFingerprints.length; i++) {
      try {
        if (allowFingerprints[i] === "sha256") {
          if (!window.sha256) window.sha256 = await import("../sha256.min.js");
          resolvedValues.sha256 = window.sha256.sha256(resolvedValues.b64);
        } else if (allowFingerprints[i] === "sha512") {
          if (!window.sha512) window.sha512 = await import("../sha512.min.js");
          resolvedValues.sha512 = window.sha512.sha512(resolvedValues.b64);
        } else if (allowFingerprints[i] === "md5") {
          if (!window.md5) window.md5 = await import("../md5.js");
          resolvedValues.md5 = window.md5.default(resolvedValues.b64);
        } else if (allowFingerprints[i] === "crc") {
          if (!window.PngToy) window.PngToy = await import("../pngtoy.min.js");
          resolvedValues.crc = await calculatePngToyCRC();
        }
      } catch (e) {
        // console.error(e);
      }
    }
  }
  return { ...resolvedValues };
}

export default calculateFingerprints;
